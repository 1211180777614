
export default {
    data() {
        return {
            any_modal_opened: false,
            overlay_active: true,
            modal_states:
            {
                // 'new-ticket': false,
                // 'calc-order-not-logged': false,
                // 'login': false,
            },
            // emittedData: null,
            active_modal_name: '',
        }
    },

    methods: {
        isModalOpened() {
            // return (Object.values(this.modal_states).findIndex(item => item && 1) + 1)
            return this.any_modal_opened
        },

        closeModals() {
            // this.any_modal_opened = false
            // for (let index in this.modal_states) {
            //     this.modal_states[index] = false
            // }
            this.$root.$emit('close-all-modals')
        },
    },

    created() {
        // console.log('modals create')
        this.$root.$on('open-modal', (data) => {
            // console.log('open modal')
            this.overlay_active = true
            this.any_modal_opened = true
            this.modal_states[data.modal] = true
            this.active_modal_name = data.modal
        })

        this.$root.$on('hide-overlay', () => {
            this.overlay_active = false
        })

        this.$root.$on('close-all-modals', (data = null) => {
            // console.log('close-all-modals',this.active_modal_name,data)
            // if (this.active_modal_name == 'register') {
            //     if (!(data && data.force_close)) return
            // }
            this.any_modal_opened = false
            for (let index in this.modal_states) {
                this.modal_states[index] = false
            }
            this.overlay_active = true
        })
    },

    beforeDestroy() {
        // console.log('modals beforeDestroy')
        this.$root.$off('open-modal')
        this.$root.$off('close-all-modals')
        this.$root.$off('hide-overlay')
    },

}    
