export const
    SITE_CLOSED = false,
    UNSTABLE_SITE = false,
    MESSAGE_TO_ALL = '',//'Здравствуйте! Мы обновляем сервис. Просим в случае возникновения ошибок в работе сервиса написать нам в техподдержку',

    PAYMENT_TOPUP = 1,
    PAYMENT_PAY = 2,
    PAYMENT_REWARD_NARRATOR = 3,
    PAYMENT_WITHDRAW_NARRATOR = 4,
    PAYMENT_REWARD_SOUNDENGINEER = 5,
    PAYMENT_WITHDRAW_CLIENT = 6,
    PAYMENT_WITHDRAW_SOUNDENGINEER = 7,

    PAYMENT_WAITING_FOR_PAYMENT = 1,
    PAYMENT_EXECUTED = 2,
    PAYMENT_REJECTED = 3,
    PAYMENT_IN_PROCESS = 5,
    PAYMENT_PAYMENT_ERROR = 6,
    PAYMENT_TRANSFER_ERROR = 7,
    PAYMENT_NULLED = 10,

    PAYMENT_OPEN_STATUSES = [PAYMENT_WAITING_FOR_PAYMENT, PAYMENT_IN_PROCESS],
    PAYMENT_WITHDRAW_TYPES = [PAYMENT_WITHDRAW_NARRATOR, PAYMENT_WITHDRAW_CLIENT, PAYMENT_WITHDRAW_SOUNDENGINEER],

    PAYMENT_CARD = 1,
    PAYMENT_INVOICE = 2,
    PAYMENT_WALLET = 3,
    PAYMENT_CRYPTO = 4,

    ORDER_DRAFT = 1,
    ORDER_SUGGESTED = 2,
    ORDER_WAITING_CALC = 3,
    ORDER_DECLINED_BY_NARRATOR = 4,
    ORDER_CALCULATED = 5,
    ORDER_DECLINED_BY_CLIENT = 6,
    ORDER_INWORK = 7,
    ORDER_VERIFY_AFTER_NARRATOR = 8,
    ORDER_COMPLETED = 9,
    ORDER_PROCESSING_BY_SOUNDENGINEER = 14,
    ORDER_WAITING_PAYMENT_FOR_SUGGESTED = 15,
    ORDER_WAITING_PAYMENT_AFTER_CALC = 16,
    ORDER_WAITING_PAYMENT_ADD_OPTIONS = 17,
    ORDER_VERIFY_BEFORE_SOUNDENGINEER = 18,
    ORDER_VERIFY_AFTER_SOUNDENGINEER = 19,

    CURRENT_ORDERS_STATUSES = [ORDER_INWORK, ORDER_VERIFY_AFTER_NARRATOR, ORDER_PROCESSING_BY_SOUNDENGINEER, ORDER_VERIFY_BEFORE_SOUNDENGINEER, ORDER_VERIFY_AFTER_SOUNDENGINEER],
    DRAFT_ORDERS_STATUSES = [ORDER_DRAFT],
    COMPLETED_ORDERS_STATUSES = [ORDER_COMPLETED],
    DECLINED_ORDERS_STATUSES = [ORDER_DECLINED_BY_NARRATOR, ORDER_DECLINED_BY_CLIENT],


    STATUS_NO_MORE_RECALC = 101,

    OPTION_CLEANING = 1,
    OPTION_MIXING = 2,
    OPTION_ALIENATION = 3,
    OPTION_FEDERAL = 4,

    GENRE_ADVERT = 1,
    GENRE_AUDIOBOOK = 2,
    GENRE_LONGREAD = 3,
    GENRE_OFFFRAME = 4,
    GENRE_YOUTUBE = 5,
    GENRE_PLAY = 6,
    GENRE_OTHER = 7,

    EVENT_CREATE_ORDER_MESSAGE = 1,
    EVENT_DELETE_ORDER_MESSAGE = 2,
    EVENT_UPDATE_ORDER_MESSAGE = 3,
    EVENT_CREATE_TICKET_MESSAGE = 4,
    EVENT_DELETE_TICKET_MESSAGE = 5,
    EVENT_UPDATE_TICKET_MESSAGE = 6,
    EVENT_UPDATE_ORDER_STATUS = 7,
    EVENT_CREATE_ORDER = 80,
    EVENT_DELETE_ORDER = 90,

    USER_BUHGALTER = 20,
    USER_SOUNDENGINEER = 2033,
    USER_SUPPORTER = 1,

    DEFAULT_IMAGE = '/assets/images/misc/default.jpg',

    //отключенные опции
    DRAFTS_ENABLED = true,
    URGENTS_ENABLED = false,
    TOPICS_ENABLED = false,
    MIXING_ENABLED = true,
    FEDERAL_ENABLED = true,
    ALIENATION_ENABLED = true,
    INVOICE_PAYMENTS_ENABLED = true,
    WALLET_PAYMENTS_ENABLED = true,
    WALLET_TOPUP_ENABLED = true,
    WALLET_TOPUP_BY_INVOICE_ENABLED = true,
    OFFRAME_GENRES_ENABLED = false,
    OTHER_GENRE_ENABLED = true,
    OPTIONS_ONLY_BEFORE_PLACEMENT_ENABLED = true,
    MUSIC_ENABLED = true,
    PARODY_ENABLED = false,
    OPTIONS_IN_PAYMENT_MODAL_ENABLED = false,
    SIMPLIFIED_ORDER_FLOAT = true,
    ADDITIONAL_ORDER_ENABLED = true,
    TG_ENABLED = true,


    LOGIN_BY_PHONE = 1,
    LOGIN_BY_EMAIL = 2,


    SERVICE_MESSAGE_NARRATOR_ALIENATION_NEEDED = 1001,
    SERVICE_MESSAGE_NARRATOR_FEDERAL_DONE = 1002,
    SERVICE_MESSAGE_NARRATOR_CLEANING_NEEDED = 1003,
    SERVICE_MESSAGE_CLIENT_WRITE_AFTER_COMPLETION = 1004,
    SERVICE_MESSAGE_CLIENT_ACT_FILES = 1005,

    DUMMY = 10000